var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":_vm.id,"size":"lg","title":"Xem file đính kèm","centered":"","hide-footer":""}},[_c('button-all-header',{staticClass:"custtom-header",attrs:{"contentBtnAdd":'Thêm',"arrayExcel":_vm.arrayExcel,"showBtnMultiDelete":_vm.showBtnMultiDelete,"hideImportFile":false,"hideExportFile":false,"hideDowload":false,"hideDelete":false,"hideAdd":false},on:{"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataDownloadFile || [],"sort-options":{
        enabled: false,
      },"select-options":{
        enabled: false,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',[_vm._v(" "+_vm._s(props.row.name)+" ")]):_vm._e(),(props.column.field == 'action')?_c('span',[_c('span',[_c('feather-icon',{attrs:{"icon":"DownloadIcon","size":"18"},on:{"click":function($event){return _vm.downloadFile(props.row.id, props.row.name)}}})],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecordDownloadFile,"currentPage":_vm.queryParams.pageNumber},on:{"pageClick":_vm.handlePageClick}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }